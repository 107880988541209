import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';

import {
  isRequiredArray,
  isEmail,
  areEquals,
  isValidPasswordArray,
} from 'fox-react/dist/utils/formValidators';
import Form from 'fox-react/dist/components/Form';
import FoxInputEmailField from 'fox-react/dist/reduxFormFields/FoxInputEmailField';
import FoxInputPasswordField from 'fox-react/dist/reduxFormFields/FoxInputPasswordField';
import FoxSwitchField from 'fox-react/dist/reduxFormFields/FoxSwitchField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import lang from 'hh-shared/dist/language/services/languageService';
import fieldNames from 'common/fieldNames';
import formNames from 'common/formNames';

import styles from './SetNewPasswordForm.module.scss';
import './SetNewPasswordFormTheming.scss';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitSucceeded: PropTypes.bool.isRequired,
};

const defaultProps = {};

function SetNewPasswordForm({ handleSubmit, submitting, submitSucceeded }) {
  const pdfLinkClassName = cx('link', styles.link);

  return (
    <div className={`${styles.form_container} reset_form_container`}>
      {submitSucceeded ? (
        <div className={styles.succeded_reset}>
          <p>{lang.labels.YourPasswordWasChanged()}</p>
          <p>
            <Link to="/login">{lang.labels.LoginPage()}</Link>
          </p>
        </div>
      ) : (
        <Form handleSubmit={handleSubmit}>
          <FoxInputEmailField
            label={lang.labels.Email()}
            placeholder={lang.labels.Email()}
            name={fieldNames.setNewPasswordForm.email}
          />

          <FoxInputPasswordField
            label={lang.labels.NewPassword()}
            placeholder={lang.labels.NewPassword()}
            name={fieldNames.setNewPasswordForm.newPassword}
          />

          <FoxInputPasswordField
            label={lang.labels.RepeatPassword()}
            placeholder={lang.labels.RepeatPassword()}
            name={fieldNames.setNewPasswordForm.repeatPassword}
          />

          <FoxSwitchField
            label={lang.labels.ReadConditions()}
            placeholder={lang.labels.ReadConditions()}
            name={fieldNames.setNewPasswordForm.isReadConditions}
            renderLink={
              <Link
                to="/documents/pdf-test.pdf"
                target="_blank"
                className={pdfLinkClassName}
              >
                {lang.labels.ClickHereToReadTheConditions()}
              </Link>
            }
          />

          <FoxSwitchField
            label={lang.labels.ReadPrinciples()}
            placeholder={lang.labels.ReadPrinciples()}
            name={fieldNames.setNewPasswordForm.isReadPrinciples}
            renderLink={
              <Link
                to="/documents/pdf-test.pdf"
                target="_blank"
                className={pdfLinkClassName}
              >
                {lang.labels.ClickHereToReadThePrinciples()}
              </Link>
            }
          />

          <FoxSwitchField
            label={lang.labels.AcceptRodo()}
            placeholder={lang.labels.AcceptRodo()}
            name={fieldNames.setNewPasswordForm.isAcceptedRodo}
            renderLink={
              <>
                <Link
                  to="/documents/pdf-test.pdf"
                  target="_blank"
                  className={pdfLinkClassName}
                >
                  {lang.labels.InformationObligation()}
                </Link>
                <Link
                  to="/documents/pdf-test.pdf"
                  target="_blank"
                  className={pdfLinkClassName}
                >
                  {lang.labels.ConsentToTheProcessingOfPersonalData()}
                </Link>
              </>
            }
          />

          <FoxButton primary disabled={submitting}>
            {lang.buttons.SetNewPassword()}
          </FoxButton>
        </Form>
      )}
    </div>
  );
}

SetNewPasswordForm.propTypes = propTypes;
SetNewPasswordForm.defaultProps = defaultProps;

const validate = values => {
  const errors = {};

  isRequiredArray(
    errors,
    values,
    [
      fieldNames.setNewPasswordForm.email,
      fieldNames.setNewPasswordForm.newPassword,
      fieldNames.setNewPasswordForm.repeatPassword,
      fieldNames.setNewPasswordForm.isReadConditions,
      fieldNames.setNewPasswordForm.isReadPrinciples,
      fieldNames.setNewPasswordForm.isAcceptedRodo,
    ],
    lang.validationMessages.FieldRequired(),
  );

  isEmail(
    errors,
    values,
    fieldNames.setNewPasswordForm.email,
    lang.validationMessages.FieldMustBeValidEmail(),
  );

  areEquals(
    errors,
    values,
    [
      fieldNames.setNewPasswordForm.newPassword,
      fieldNames.setNewPasswordForm.repeatPassword,
    ],
    lang.validationMessages.PasswordsNotEquals(),
  );

  isValidPasswordArray(
    errors,
    values,
    [
      fieldNames.setNewPasswordForm.newPassword,
      fieldNames.setNewPasswordForm.repeatPassword,
    ],
    lang.validationMessages.NotValidPassword(),
  );

  return errors;
};

export default reduxForm({
  form: formNames.setNewPasswordForm,
  validate,
})(SetNewPasswordForm);
