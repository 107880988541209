import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import createFoxGridContainer from 'fox-react/dist/components/FoxGrid/createFoxGridContainer';
import FoxGridColumn from 'fox-react/dist/components/FoxGrid/FoxGridColumn';

import lang from 'hh-shared/dist/language/services/languageService';
import columnNames from 'common/columnNames';
import gridNames from 'common/gridNames';
import moment from 'moment';
import TimeTriggerLabelCell from 'hh-shared/dist/components/grid/TimeTriggerLabelCell';
import InnerContentWrapper from 'hh-shared/dist/components/layout/InnerContentWrapper';
import DateTimeColumnCell from 'hh-shared/dist/components/grid/DateTimeColumnCell';
import NumericCell from 'hh-shared/dist/components/grid/NumericCell';
import WinningCell from 'commons/WinningCell';
import StarHeaderCell from 'hh-shared/dist/components/grid/StarHeaderCell';
import StarCell from 'hh-shared/dist/components/grid/StarCell';
import DropdownLinkCell from 'hh-shared/dist/components/commons/Dropdown/DropdownLinkCell';
import auctionStatusIds from 'hh-shared/dist/utils/auctionStatusesIds';

import { getForGrid, isObservedChange } from './actions';
import AuctionStatusCell from './AuctionStatusCell';

const propTypes = {
  match: PropTypes.any.isRequired,
};

const defaultProps = {};

const FoxGridContainer = createFoxGridContainer(gridNames.auctions);

function AuctionsPage({ match }) {
  const dispatch = useDispatch();
  const onLoadData = dataState => dispatch(getForGrid(dataState));
  const { currentUser } = useSelector(state => state.auth);
  const onIsObservedChange = (id, isObserved) => {
    dispatch(isObservedChange(id, isObserved));
  };

  return (
    <InnerContentWrapper>
      <FoxGridContainer
        onLoadData={onLoadData}
        defaultSortField={columnNames.auctions.status}
      >
        <FoxGridColumn
          field={columnNames.auctions.isObserved}
          title={lang.labels.Observed()}
          filter="boolean"
          columnMenu={false}
          cell={({ dataItem, className }) => (
            <StarCell
              value={dataItem.isObserved}
              onChange={() =>
                onIsObservedChange(dataItem.id, !dataItem.isObserved)
              }
              className={className}
            />
          )}
          headerCell={StarHeaderCell}
        />
        <FoxGridColumn
          field={columnNames.auctions.number}
          title={lang.labels.AuctionNumber()}
          filter="text"
          locked
          cell={({ dataItem, className }) => (
            <DropdownLinkCell
              link={`${match.url}/${dataItem.id}`}
              linkTitle={lang.labels.Preview()}
              text={dataItem.number}
              className={className}
            />
          )}
        />
        <FoxGridColumn
          field={columnNames.auctions.status}
          title={lang.labels.CurrentAuctionStatus()}
          filter="text"
          cell={AuctionStatusCell}
        />
        <FoxGridColumn
          field={columnNames.auctions.currentPrice}
          title={lang.labels.CurrentPrice()}
          filter="text"
          cell={({ dataItem }) =>
            !dataItem.lastBidCompanyInstanceId ? (
              <TimeTriggerLabelCell
                value={dataItem.currentPrice || lang.labels.WithoutOffer()}
                label={lang.labels.NoOffer()}
                timeFrom={moment(dataItem.endTime).subtract(2, 'hours')}
                timeTo={moment(dataItem.endTime)}
                displayAsNumericValue
                orange
              />
            ) : (
              <WinningCell
                auctionId={dataItem.id}
                isCompeting={dataItem.statusId !== auctionStatusIds.closed}
                isWinning={
                  dataItem.lastBidCompanyInstanceId ===
                  currentUser.companyInstanceId
                }
                value={dataItem.currentPrice || lang.labels.WithoutOffer()}
                displayLabel={false}
                displayAsNumericValue
              />
            )
          }
        />
        <FoxGridColumn
          field={columnNames.auctions.distance}
          title={lang.labels.Distance()}
          filter="numeric"
          cell={({ dataItem }) => <NumericCell value={dataItem.distance} />}
        />
        <FoxGridColumn
          field={columnNames.auctions.zipCodeFrom}
          title={lang.labels.ZipCodeFrom()}
          filter="text"
        />
        <FoxGridColumn
          field={columnNames.auctions.cityFrom}
          title={lang.labels.CityFrom()}
          filter="text"
        />
        <FoxGridColumn
          field={columnNames.auctions.capacity}
          title={`${lang.labels.Capacity()} (kg)`}
          filter="text"
          cell={({ dataItem }) => <NumericCell value={dataItem.distance} />}
        />
        <FoxGridColumn
          field={columnNames.auctions.unloadingMethod}
          title={lang.labels.UnloadingMethod()}
          filter="text"
        />
        <FoxGridColumn
          field={columnNames.auctions.zipCodeTo}
          title={lang.labels.ZipCodeTo()}
          filter="text"
        />
        <FoxGridColumn
          field={columnNames.auctions.cityTo}
          title={lang.labels.CityTo()}
          filter="text"
        />
        {/* TODO -> przerzucić do nowej komórki np. BooleanCell, gdy do hh-shared zostaną podpięte tłumaczenia */}
        <FoxGridColumn
          field={columnNames.auctions.isParticipant}
          title={lang.labels.IsParticipant()}
          filter="boolean"
          columnMenu={false}
          cell={({ dataItem }) => (
            <td colSpan="1">
              {dataItem.isParticipant ? lang.labels.Yes() : lang.labels.No()}
            </td>
          )}
        />
        <FoxGridColumn
          field={columnNames.auctions.isWon}
          title={lang.labels.IsWon()}
          filter="boolean"
          columnMenu={false}
          cell={({ dataItem }) => (
            <td colSpan="1">
              {dataItem.lastBidCompanyInstanceId ===
              currentUser.companyInstanceId
                ? lang.labels.Yes()
                : lang.labels.No()}
            </td>
          )}
        />
        <FoxGridColumn
          field={columnNames.auctions.unloadingTime}
          title={lang.labels.UnloadingTime()}
          filterable={false}
          filter="date"
          cell={DateTimeColumnCell}
        />
      </FoxGridContainer>
    </InnerContentWrapper>
  );
}

AuctionsPage.propTypes = propTypes;
AuctionsPage.defaultProps = defaultProps;

export default AuctionsPage;
