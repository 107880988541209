import React from 'react';
import PropTypes from 'prop-types';
import { FormSection } from 'redux-form';
import FoxInputTextField from 'fox-react/dist/reduxFormFields/FoxInputTextField';
import FoxButton from 'fox-react/dist/components/FoxButton';
import { isRequiredFieldValidator } from 'hh-shared/dist/utils/formValidators';
import lang from 'hh-shared/dist/language/services/languageService';
import fieldNames from 'common/fieldNames';
import DriverAccordionItem from './DriverAccordionItem';
import styles from './CompanyFormDriver.module.scss';

const propTypes = {
  fields: PropTypes.array.isRequired,
};

function CompanyFormDriver({ fields }) {
  const firstUpperLetter = text =>
    !text ? '' : `${text[0].toUpperCase()}${text.slice(1)}`;

  return (
    <>
      {fields.map((member, index) => (
        <DriverAccordionItem
          key={index}
          driverIndex={index}
          onRemove={() => fields.remove(index)}
        >
          <FormSection name={member} key={index}>
            <FoxInputTextField
              label={lang.labels.FirstName()}
              placeholder={lang.labels.FirstName()}
              name={fieldNames.newDriverForm.firstName}
              // parse={firstUpperLetter}
              required
              validate={[isRequiredFieldValidator]}
            />

            <FoxInputTextField
              label={lang.labels.LastName()}
              placeholder={lang.labels.LastName()}
              name={fieldNames.newDriverForm.lastName}
              // parse={firstUpperLetter}
              required
              validate={[isRequiredFieldValidator]}
            />

            <FoxInputTextField
              label={lang.labels.PhoneNumber()}
              placeholder={lang.labels.PhoneNumber()}
              name={fieldNames.newDriverForm.phoneNumber}
            />
          </FormSection>
        </DriverAccordionItem>
      ))}
      <FoxButton
        type="button"
        onClick={() => fields.push({})}
        className={`k-button k-primary ${styles.accordion_button}`}
      >
        {lang.buttons.AddNewDriver()}
      </FoxButton>
    </>
  );
}

CompanyFormDriver.propTypes = propTypes;

export default CompanyFormDriver;
