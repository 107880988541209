import React from 'react';
import PropTypes from 'prop-types';
import ContentWrapper from 'hh-shared/dist/components/layout/ContentWrapper';
import ProgressiveImg from 'hh-shared/dist/components/layout/ProgressiveImg';
import Footer from 'hh-shared/dist/components/layout/Footer';
import styles from './DefaultAnonymousLayout.module.scss';
import backgroundImage from '../resources/images/Login_background.jpg';
import backgroundPlaceholderImage from '../resources/images/Login_background_placeholder.jpg';

const propTypes = {
  children: PropTypes.any,
};

const defaultProps = {
  children: undefined,
};

function DefaultAnonymousLayout({ children }) {
  return (
    <>
      <div className={styles.main_container}>
        <div className={styles.left_side}>
          <ContentWrapper
            additionalClassNames={styles.login_wrapper}
            anonymousContainer
          >
            {children}
          </ContentWrapper>
        </div>
        <div className={styles.right_side}>
          <ProgressiveImg
            imageSrc={backgroundImage}
            placeholderSrc={backgroundPlaceholderImage}
            className={styles.background_image}
            alt="Background"
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

DefaultAnonymousLayout.propTypes = propTypes;
DefaultAnonymousLayout.defaultProps = defaultProps;

export default DefaultAnonymousLayout;
